import { getWxUrl } from '@/utils';
import { message } from 'antd';
import { observable } from 'mobx';
import ShareCardModel from '../components/shareCard/model';

export default class MarketingModel {
  @observable shareCardStore = new ShareCardModel();
  @observable public marketingList = [
    {
      title: '拉新工具',
      list: [
        {
          title: '推荐职位',
          subtitle: '适用主播拉新场景',
          btnText: '立即推荐',
          permissionId: '71',
          onClick: () => {
            window.top.RingPermission.openTab('/pbb-pc-management/positionManagement/index', '76', '职位管理');
          },
        },
        {
          title: '推荐切片',
          subtitle: '适用雇主拉新场景',
          btnText: '立即推荐',
          permissionId: '66',
          onClick: () => {
            window.top.RingPermission.openTab('/pbb-pc-management/section/sectionAdmin/index', '74', '切片管理');
          },
        },
        {
          title: '上传简历',
          subtitle: '适用主播自主上传简历',
          btnText: '复制链接',
          permissionId: '72',
          onClick: async () => {
            try {
              const str = await getWxUrl({
                channel: 2,
              });
              this.copyToClipboard(str);
            } catch (error) {
              console.error(error);
            }
          },
          btnTextTwo: '生成海报',

          onclickTwo: async () => {
            try {
              const str = await getWxUrl({
                channel: 2,
              });
              this.shareCardStore.onDwonload(2, str);
            } catch (error) {
              console.error(error);
            }
          },
        },
        {
          title: '发布职位',
          subtitle: '适用雇主自主发布职位',
          btnText: '复制链接',
          permissionId: '73',
          onClick: async () => {
            try {
              const str = await getWxUrl({
                channel: 7,
              });
              this.copyToClipboard(str);
            } catch (error) {
              console.error(error);
            }
          },
          btnTextTwo: '生成海报',
          btnTextTwoLoading: false,
          onclickTwo: async () => {
            try {
              const str = await getWxUrl({
                channel: 7,
              });
              this.shareCardStore.onDwonload(7, str);
            } catch (error) {
              console.error(error);
            }
          },
        },
      ],
    },
    {
      title: '销转工具',
      list: [
        {
          title: '录播课',
          subtitle: '适用引导主播购买服务',
          btnText: '复制链接',
          permissionId: '146',
          onClick: async () => {
            try {
              const str = await getWxUrl({
                query: 'source=1,servicePackageId=13',
                channel: 12,
              });
              this.copyToClipboard(str);
            } catch (error) {
              console.error(error);
            }
          },
          btnTextTwo: '生成海报',
          btnTextTwoLoading: false,
          onclickTwo: async () => {
            try {
              const str = await getWxUrl({
                query: 'source=1,servicePackageId=13',
                channel: 12,
              });
              this.shareCardStore.onDwonload(12, str);
            } catch (error) {
              console.error(error);
            }
          },
        },
        {
          title: '管家代招服务',
          subtitle: '适用引导雇主购买服务',
          btnText: '复制链接',
          permissionId: '76',
          onClick: async () => {
            try {
              const str = await getWxUrl({
                query: 'packageType=7',
                channel: 8,
              });
              this.copyToClipboard(str);
            } catch (error) {
              console.error(error);
            }
          },
          btnTextTwo: '生成海报',
          btnTextTwoLoading: false,
          onclickTwo: async () => {
            try {
              const str = await getWxUrl({
                query: 'packageType=7',
                channel: 8,
              });
              this.shareCardStore.onDwonload(8, str);
            } catch (error) {
              console.error(error);
            }
          },
        },

        {
          title: '进入全国主播通告群服务',
          subtitle: '适用引导主播购买服务',
          btnText: '复制链接',
          permissionId: '76',
          onClick: async () => {
            try {
              const str = await getWxUrl({
                channel: 11,
                // query: 'from=launch',
              });
              this.copyToClipboard(str);
            } catch (error) {
              console.error(error);
            }
          },
          btnTextTwo: '生成海报',
          btnTextTwoLoading: false,
          onclickTwo: async () => {
            try {
              const str = await getWxUrl({
                channel: 11,
              });
              this.shareCardStore.onDwonload(11, str);
            } catch (error) {
              console.error(error);
            }
          },
        },

        {
          title: '自主建联卡',
          subtitle: '适用引导雇主购买服务',
          btnText: '复制链接',
          permissionId: '76',
          onClick: async () => {
            try {
              const str = await getWxUrl({
                channel: 8,
                query: 'packageType=12',
              });
              this.copyToClipboard(str);
            } catch (error) {
              console.error(error);
            }
          },
          btnTextTwo: '生成海报',
          btnTextTwoLoading: false,
          onclickTwo: async () => {
            try {
              const str = await getWxUrl({
                channel: 8,
                query: 'packageType=12',
              });
              this.shareCardStore.onDwonload(8, str);
            } catch (error) {
              console.error(error);
            }
          },
        },
      ],
    },
    {
      title: '营销玩法',
      list: [
        {
          title: '评价发红包',
          subtitle: '适用奖励主播到面评价',
          btnText: '发红包',
          permissionId: '77',
          onClick: () => {
            window.top.RingPermission.openTab('/pbb-pc-management/redEnvelope/index', '79', '评价发红包');
          },
        },
      ],
    },
  ];
  private copyToClipboard = async (text: string) => {
    if (text === '') {
      throw new Error('链接为空');
    } else {
      if (navigator.clipboard && window.isSecureContext) {
        // 尝试使用新的 Clipboard API
        try {
          await navigator.clipboard.writeText(text);
          message.success('复制成功');
        } catch (err) {
          console.error('Failed to copy text using Clipboard API: ', err);
        }
        return;
      }

      // 回退到使用 document.execCommand 方法
      const textarea = document.createElement('textarea');
      textarea.value = text;
      textarea.setAttribute('readonly', '');
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';
      document.body.appendChild(textarea);
      textarea.select();
      textarea.setSelectionRange(0, textarea.value.length);

      try {
        const successful = document.execCommand('copy');
        if (successful) {
          message.success('复制成功');
        } else {
          throw new Error('Failed to copy text using execCommand');
        }
      } catch (err) {
        console.error('Failed to copy text using execCommand: ', err);
      } finally {
        document.body.removeChild(textarea);
      }
    }
  };
}
